import React from 'react';
import './Admission.css';
import NavBar from '../Navbar/Navbar';
import Fotter from '../Sections/Footer';
import MyForm from './Form';



function AdmissionForm() {
  return (
    
    <>
    <div className="App">
      <div className="hero-section-admission">
      <NavBar/>
        {/* <img src="/path/to/your-bg-image.jpg" alt="Background" className="bg-image" /> */}
        <h1 className="hero-title-admission">Welcome to Our Institute</h1>
      </div>

      <div className="admission-container">
        <div className="left-column">
          {/* <div className="steps">
            <h2>Steps</h2>
            <ol>
              <li>Fill out the admission form.</li>
              <li>Submit the form.</li>
              <li>Wait for admission confirmation.</li>
            </ol>
          </div> */}

          <div className="description">
            <h2>🎓 Admission Process:</h2>
            <p>

Ready to join the Biits Academy family? Our admission process is designed to be seamless and inclusive.

    Inquiry: Start by expressing your interest in Biits Academy. You can reach out to us via our website or visit our campus for a personal tour.

    Application: Complete the online application form, providing necessary details about the prospective student.

    Assessment: As part of our commitment to personalized learning, we may conduct an assessment to understand each student's strengths and areas for development.

    Meet Our Team: Schedule a meeting with our admissions team to discuss any questions you may have and to learn more about our educational philosophy.

    Welcome to Biits Academy: Once the admission process is complete, we look forward to welcoming you to Biits Academy! Prepare for an enriching educational experience and a journey of growth and discovery.</p>
          </div>

          <div className="about-institute">
            <h2>About Our Institute</h2>
            <p>At Biits Academy, we believe in the transformative power of education. Our commitment is to provide a nurturing and innovative learning environment where students can thrive academically, creatively, and socially. We invite you to embark on a journey of knowledge, growth, and endless possibilities with us.

🏫 About Biits Academy:</p>
          </div>
        </div>

        <div className="right-column">
          <MyForm/>
        </div>
      </div>
    </div>
    <Fotter/>
    </>
  );
}

export default AdmissionForm;
