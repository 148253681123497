import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom'; 
import './Footer.css';
import logo from '../Assests/BIITS_LOGO_Details/BIITS_LOGO_SECONDARY/BIITS-Secondary_LOGO-White.png';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-section logo-section">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="about-us">
            <p>At Biits Academy Here We Prepare Student For Industry And Make Them Future Bright & Clear</p>
          </div>
        </div>
        <div className="footer-section quick-links-section">
          <h3>Quick Links</h3>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/products">Courses</Link></li>
            <li><Link to="/admission">Admission</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
        <div className="footer-section contact-section">
          <h3>Contact</h3>
          <p>Email: <a href='mailto:biitsacademy48@gmail.com'>biitsacademy48@gmail.com</a></p>
          <p>Phone: <a href='tel:+91 8949069322'>+91 8949069322</a></p>
          <div className="social-icons">
            <a href="#" className="icon">
              <FaFacebook size={30} />
            </a>
            <a href="https://www.instagram.com/biits_academy_/" className="icon">
              <FaInstagram size={30} />
            </a>
            <a href="#" className="icon">
              <FaYoutube size={30} />
            </a>
          </div>
        </div>
      </div>
      <div className="copyright">
        &copy; 2023 biits_academy_. All Rights Reserved.
      </div>
    </div>
  );
}

export default Footer;
