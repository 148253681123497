// Engineer.js
import React from 'react';
import styled, { keyframes } from 'styled-components';

const moveAnimation = keyframes`
  0% { transform: translateX(0); }
  50% { transform: translateX(10px); }
  100% { transform: translateX(0); }
`;

const EngineerWrapper = styled.div`
  text-align: center;
`;

const EngineerImage = styled.img`
  width: 150px;
  animation: ${moveAnimation} 1s infinite;
`;

const Engineer = () => {
  return (
    <EngineerWrapper>
      <EngineerImage
        src="https://example.com/engineer-image.jpg" // Replace with your engineer image URL
        alt="Engineer"
      />
      <p>This page does not exist!</p>
    </EngineerWrapper>
  );
};

export default Engineer;
