import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Assests/biits-removebg-preview (1).png';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo">
            <img src={Logo} width="40%" alt="logo" />
          </Link>
          <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <ul className={`nav-menu ${isOpen ? 'active' : ''}`}>
            <li className="nav-item">
              <Link to="/" onClick={toggleMenu} className="nav-link">Home</Link>
            </li>
            <li className="nav-item">
              <Link to="/about" onClick={toggleMenu} className="nav-link">About</Link>
            </li>
            <li className="nav-item">
              <Link to="/products" onClick={toggleMenu} className="nav-link">Courses</Link>
            </li>
            <li className="nav-item">
              <Link to="/admission" onClick={toggleMenu} className="nav-link">Admission</Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" onClick={toggleMenu} className="nav-link">Contact</Link>
            </li>
            {/* <li className="nav-item">
              <Link to="https://www.000webhost.com/" onClick={toggleMenu} className="nav-link">
                <FontAwesomeIcon icon={faUser} /> Login
              </Link>
            </li> */}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
