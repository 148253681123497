
import React from 'react';
import Engineer from './Engineer';
import styled from 'styled-components';

const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const NotFound = () => {
  return (
    <NotFoundWrapper>
      <h1>404 - Not Found</h1>
      <Engineer />
    </NotFoundWrapper>
  );
};

export default NotFound;
